import { useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

export function UncodeTranslate(props) {
    const {
        pt,
        ptPT,
        es,
        en,
        fr,
        de,
        it,
        ja,
        ko,
        zh,
        ru,
        autor,
        borderRadius,
        backgroundColor,
        padding,
        arrowColor,
        flag,
    } = props

    useEffect(() => {
        // Remover qualquer instância anterior para evitar duplicação
        const existingTranslateElement = document.getElementById(
            "google_translate_element"
        )
        if (existingTranslateElement) {
            existingTranslateElement.innerHTML = ""
        }

        const googleTranslateScript = document.createElement("script")
        googleTranslateScript.src =
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        document.body.appendChild(googleTranslateScript)

        window.googleTranslateElementInit = () => {
            const userLanguage = navigator.language.split("-")[0]
            const includedLanguages = [
                pt && "pt",
                ptPT && "pt-PT",
                es && "es",
                en && "en",
                fr && "fr",
                de && "de",
                it && "it",
                ja && "ja",
                ko && "ko",
                zh && "zh-CN",
                ru && "ru",
            ]
                .filter(Boolean)
                .join(",")

            new window.google.translate.TranslateElement(
                {
                    pageLanguage: userLanguage, // Idioma original da página baseado no idioma do navegador
                    includedLanguages, // Idiomas para tradução
                    layout: window.google.translate.TranslateElement
                        .InlineLayout.SIMPLE,
                    autoDisplay: false,
                },
                "google_translate_element"
            )
        }

        const style = document.createElement("style")
        style.innerHTML = `
            .goog-te-banner-frame.skiptranslate {
                display: none !important;
            }
            body {
                top: 0px !important;
            }
            #\\:2\\.container {
                display: none !important;
            }
            .goog-te-gadget-icon {
                display: none !important;
            }
            .goog-te-gadget-simple {
                background-color: ${backgroundColor} !important;
                border-radius: ${borderRadius}px !important;
                padding: ${padding}px !important;
                border: 0 !important;
                font-size: 10pt;
                font-weight: 800;
                display: inline-block;
                cursor: pointer;
                zoom: 1;
                position: relative;
                color: #3e3065 !important;
            }
            .goog-te-gadget-simple .goog-te-menu-value {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
            }
            .goog-te-gadget-simple .goog-te-menu-value:after {
                content: '▼';
                color: ${arrowColor} !important;
                margin-left: 8px;
                display: inline-block;
                transform: translateY(-2px);
            }
            .goog-te-gadget-simple .goog-te-menu-value span {
                display: inline-block;
            }
            .goog-te-gadget-simple .goog-te-menu-value img {
                display: ${flag ? "inline-block" : "none"};
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
            .VIpgJd-ZVi9od-aZ2wEe-wOHMyf,
            .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
                display: none !important;
            }
        `
        document.head.appendChild(style)

        return () => {
            document.body.removeChild(googleTranslateScript)
            document.head.removeChild(style)
        }
    }, [
        pt,
        ptPT,
        es,
        en,
        fr,
        de,
        it,
        ja,
        ko,
        zh,
        ru,
        borderRadius,
        backgroundColor,
        padding,
        arrowColor,
        flag,
    ])

    return (
        <div>
            <div id="google_translate_element"></div>
            <div>{autor}</div>
        </div>
    )
}

// Definição das propriedades do componente
addPropertyControls(UncodeTranslate, {
    pt: {
        type: ControlType.Boolean,
        title: "PT",
        defaultValue: true,
        description: "Português do Brasil",
    },
    ptPT: {
        type: ControlType.Boolean,
        title: "PT-PT",
        defaultValue: false,
        description: "Português de Portugal",
    },
    es: {
        type: ControlType.Boolean,
        title: "ES",
        defaultValue: true,
        description: "Espanhol",
    },
    en: {
        type: ControlType.Boolean,
        title: "EN",
        defaultValue: true,
        description: "Inglês",
    },
    fr: {
        type: ControlType.Boolean,
        title: "FR",
        defaultValue: true,
        description: "Francês",
    },
    de: {
        type: ControlType.Boolean,
        title: "DE",
        defaultValue: true,
        description: "Alemão",
    },
    it: {
        type: ControlType.Boolean,
        title: "IT",
        defaultValue: true,
        description: "Italiano",
    },
    ja: {
        type: ControlType.Boolean,
        title: "JA",
        defaultValue: false,
        description: "Japonês",
    },
    ko: {
        type: ControlType.Boolean,
        title: "KO",
        defaultValue: false,
        description: "Coreano",
    },
    zh: {
        type: ControlType.Boolean,
        title: "ZH",
        defaultValue: false,
        description: "Chinês Simplificado",
    },
    ru: {
        type: ControlType.Boolean,
        title: "RU",
        defaultValue: false,
        description: "Russo",
    },
    borderRadius: {
        type: ControlType.Number,
        title: "Borda Arredondada",
        defaultValue: 4,
        description: "Define o raio da borda do elemento de tradução.",
    },
    backgroundColor: {
        type: ControlType.Color,
        title: "Cor de Fundo",
        defaultValue: "#ffffff",
        description: "Define a cor de fundo do elemento de tradução.",
    },
    padding: {
        type: ControlType.Number,
        title: "Espaçamento Interno",
        defaultValue: 8,
        description:
            "Define o espaçamento interno (padding) do elemento de tradução.",
    },
    arrowColor: {
        type: ControlType.Color,
        title: "Cor da Seta",
        defaultValue: "#000000",
        description: "Define a cor da seta do dropdown.",
    },
    flag: {
        type: ControlType.Boolean,
        title: "Mostrar Bandeira",
        defaultValue: true,
        description: "Define se a bandeira do idioma deve ser exibida.",
    },
    autor: {
        type: ControlType.String,
        placeholder: "Autor: ",
        description:
            "Comentário de autoria. Code by UncodeStack and @omatusaelhorch.",
    },
})
